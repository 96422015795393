<template>
    <dialog-form
        :open="open"
        @close="$emit('close')"
        title="Create Report"
        :submit-button-title="submitTitle"
        @submit="onSubmit"
        :saving="loading"
    >
        <template #form-content>
            <div v-if="canExport">
                <div>
                    <div style="display: flex; align-items: center">
                        <v-checkbox v-model="showPasswordField" color="secondary" ></v-checkbox>
                        Password Protect PDF

                    </div>
                    <div v-if="showPasswordField" style="margin-bottom: 20px">
                        <AppTextField row label="PDF Password" v-model="pdfPassword" type="password" ref="password"></AppTextField>
                    </div>
                    <div v-else style="color: var(--v-text-lighten2)">
                        Reports contain Personal Health Information (PHI) on the patient. We highly encourage protecting this report with a password.
                    </div>

                </div>


                <AppTabs v-model="currentTab" color="secondary" underline>
                    <AppTab>Selected Test</AppTab>
                    <AppTab>Included Tests</AppTab>
                </AppTabs>
                <div v-if="currentTab === 0">
                    <div style="color: #757575">
                        Select which test you would like to be the primary focus of this report.
                    </div>
	                <div style="margin-bottom: 10px; font-size: 12px; color: #757575">
		                *Covid only tests not included in reports
	                </div>

                    <div v-for="(test, index) in profile.tests" :key="index" v-if="!test.isCovidOnly">

                        <div
                            class="test-card"
                            :class="{'selected-test-card': test.id === selectedTestId}"
                            @click="selectedTestId = test.id"
                        >
                            <TestTypeDot
                                :has-duplicate-tests="test.data.hasDuplicateTests"
                                :is-dot-excluded="test.data.isExcluded"
                                :is-non-baseline="test.data.isEvent"
                                style="margin-right: 5px"
                            />
                            <!--                        <div -->
                            <!--                            class="dot"-->
                            <!--                            :class="{-->
                            <!--                            baseline: !test.data.isEvent && !test.data.isExcluded, -->
                            <!--                            event: test.data.isEvent && !test.data.isExcluded, -->
                            <!--                            excluded: test.data.isExcluded}"-->
                            <!--                        >-->

                            <!--                        </div>-->
                            {{ test.displayDate }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div style=" color: #757575">
                        Select which test you would like to be the included in this report. By default, all tests are included.
                    </div>
	                <div style="margin-bottom: 10px; font-size: 12px; color: #757575">
		                *Covid only tests not included in reports
	                </div>
                    <div v-for="(test, index) in includedTests" :key="index" >
                        <div
                            class="test-card"
                            :class="{'selected-test-card': test.isIncluded}"
                            @click="test.isIncluded = !test.isIncluded"
                        >
                            <TestTypeDot
                                :has-duplicate-tests="test.data.hasDuplicateTests"
                                :is-dot-excluded="test.data.isExcluded"
                                :is-event="test.data.isEvent"
                                style="margin-right: 5px"
                            />
                            {{ test.displayDate }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                PDF reports are not available for profiles that have no tests or sessions, or have only taken COVID screenings.
            </div>
            
        </template>
    </dialog-form>
</template>

<script>
import moment from "moment";
import TestTypeDot from "@/components/TestTypeDot";

export default {
    name: "CreateReportDialogForm",
    components: {TestTypeDot},
    props: {
        open: {
            type: Boolean
        },
        profile: {
            required: true
        },
        loading: {
            type: Boolean
        }
    },
    data() {
        return {
            currentTab: 0,
            selectedTestId: null,
            includedTests: [],
            showPasswordField: true,
            pdfPassword: ''
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        profile() {
            this.init()
        },
        showPasswordField(newValue) {
            if (!newValue)
                this.pdfPassword = ''
        }
    },
    methods: {
        init() {
            if (!this.profile) return
            // this.selectedTestId = this.profile.tests[0].id;
            this.selectedTestId = this.profile.tests.find(x => !x.isCovidOnly)?.id
			let includedTests = []
            this.profile.tests.forEach(test => {
				if (!test.isCovidOnly) {
					includedTests.push({
						...test,
						isIncluded: true,
					})
				}
            })
	        
	        this.includedTests = includedTests
            
        },
        onSubmit() {
            if (!this.canExport) {
                this.$emit('close');
                return;
            }

            let includedTestIds = [];
            this.includedTests.forEach(test => {
                if (test.isIncluded) includedTestIds.push(test.id)
            })
            
            this.$emit('createReport', {
                selectedTestId: this.selectedTestId,
                includedTestIds: includedTestIds,
                pdfPassword: this.pdfPassword
            })
        }
    },
    computed: {
        canExport: function() {
            return this.profile?.hasAtLeastOneNonCovid && this.profile?.tests?.length > 0
        },
        submitTitle: function() {
            return this.canExport ? "Create Report" : "Cancel";
        }
    }
}
</script>

<style scoped>
.test-card {
    border: 1px solid var(--v-border-base); 
    margin: 5px 0; 
    border-radius: 5px; 
    padding: 10px;
    display: flex;
    align-items: center;
}

.test-card:hover {
    cursor: pointer;
}

.selected-test-card {
    background-color: rgba(130,203,195,.2);
    border: 1px solid rgba(130,203,195,1)
}

.dot {
    height: 8px; 
    width: 8px; 
    border-radius: 5px; 
    background-color: red; 
    margin-right: 10px;
}

.baseline {
    background-color: white;
    border: 2px solid var(--v-primary-base)
}

.event {
    background-color: var(--v-event-base);
    border: 2px solid var(--v-event-base)
}

.excluded {
    background-color: var(--v-charcoal-base);
    border: 2px solid var(--v-charcoal-base)
}

</style>